@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
    font-family: 'Open Sans', sans-serif !important;
}

/* Actions */
.all-actions {
    width: 300px !important;
    max-width: 300px !important;
}

/* Silck Slider Styles*/
.slick-slider {
    width: 100%;
    height: 100%;
}

.slick-list,
.slick-track,
.slick-slide > div {
    height: 100%;
}

.cZtcDl {
    font-size: 13px !important;
}

.iOlZKJ {
    color: #ef233c;
    font-weight: 500;
}

.image-gallery-slide img {
    width: 100%;
    height: 450px !important;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 70px !important;
    width: 31px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px !important;
    object-fit: contain;
}
/*

.slick-dots li button:before {
    font-size: 25px !important;
    color: rgb(155, 6, 6) !important;
    width: 30px;
    height: 30px;
}

.slick-dots li.slick-active button:before {
    color: rgb(255, 253, 253) !important;
}

.slick-dots {
    bottom: 0px !important;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
} */

.trans {
    transition: 0.3s ease-in-out;
}

.tshadow {
    text-shadow: 3px 3px #fff;
}

td:hover .ProductName {
    transition: all 0.6s ease-in-out;
    width: 100%;
    overflow: auto;
}
td .ProductName::-webkit-scrollbar {
    display: none;
}
